// Import necessary components and utilities
import { Field, Form, Formik } from "formik";
import ErrorBox from "../shared/ErrorBox";
import util from '../utils/util'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingColumns, faCheckCircle, faCreditCard, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import useSignUp from "../functions/useSignUp";
import { useEffect, useRef } from "react";
import MaskedInput from 'react-text-mask';
import { SignUpStore } from "../stores/SignUpStore";
import LoadingModal from "./LoadingModal";
import Swal from "sweetalert2";
import { CREDIT_DEBIT_CARD, ACH } from "../shared/constants";


// Define SignUpForm component
const SignUpForm = () => {

    const { validateEmailForUse, cssStyleInput, phoneNumberMask, zipMask } = useSignUp();

    const state = SignUpStore.useState(s => s);

    const formRef = useRef()
    const formRefACH = useRef()

    useEffect(() => {
        window.addEventListener('message', function (event) {
            if (typeof event.data === 'string' && JSON.stringify(event.data).includes("message")) {
                const token = JSON.parse(event.data);
                const { errorCode, errorMessage } = token;
                if (errorCode === "" || errorCode === "0") {
                    document.getElementById('mytoken').value = token.token;
                    document.getElementById('expiry').value = token.expiry;
                    document.getElementById("tokenform").classList.remove("formErrorCard");
                } else {
                    switch (errorMessage) {
                        case "Invalid CVV": console.log("Invalid CVV"); break;
                        case "Invalid Expiry": console.log("Invalid Expiry"); break;
                        case "Invalid Account Number": console.log("Invalid Account Number"); break;
                        default:
                            break;
                    }
                }
            }

        }, false);
    }, [])

    function countdown() {
        let seconds = process.env.REACT_APP_ENVIROMENT === 'Production' ? 60 : 1;
        const interval = setInterval(() => {
            document.getElementsByClassName('swal2-confirm')[0].innerText = `Go to the App (${seconds})`;
            seconds--;
            if (seconds < 0) {
                clearInterval(interval);
                console.log("Countdown finished");
                document.getElementsByClassName('swal2-confirm')[0].innerText = `Go to the App`;
                document.getElementsByClassName('swal2-confirm')[0].disabled = false;
            }
        }, 1000);
    }

    const submitForm = async (values) => {

        if (state.paymenthMethod === ACH) {
            const data = {
                ...state,
                ...values
            }
            await startPayment(data);
        } else {

            const cctoken = document.getElementById("mytoken").value;
            const expiry = document.getElementById("expiry").value;
            const cardHolderName = document.getElementById("cardHolderNameId").value;
            const data = {
                ...state,
                ...values,
                cctoken,
                expiry,
                cardHolderName,
            }
            await startPayment(data);
        }

    }

    const startPayment = async (values) => {
        try {

            let tempValues = { ...values };

            SignUpStore.update(s => {
                s.loading = true;
            });

            // Validate if is ACH or Credit/Debit Card
            if (state.paymenthMethod === ACH) {
                tempValues = {
                    ...tempValues,
                    cardHolderName: document.getElementById("cardHolderNameId").value,
                    accountNumber: document.getElementById("accountNumber").value,
                    routingNumber: document.getElementById("routingNumber").value,
                    accountType: document.getElementById("accountType").value,
                    paymenthMethod: state.paymenthMethod,
                }
            }

            const request = await util.axios.post(`/propelrpay/create_subscriber`, tempValues);
            const { error, msg } = request.data;
            if (error) throw msg;
            SignUpStore.update(s => {
                s.loading = false;
            });
            Swal.fire({
                title: 'Account created successfully!',
                html: `
        <p>Please watch the following video with important instructions.</p>
        <iframe width="100%" height="315" src="https://www.youtube.com/embed/GpEwriSZVMI?si=gs18sl_ih5gbfU35&autoplay=1&rel=0&controls=0&cc_load_policy=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
                icon: 'success',
                confirmButtonText: 'Go to the App',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    countdown();
                    document.getElementsByClassName('swal2-confirm')[0].disabled = true;
                },

            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = process.env.REACT_APP_REVREX_SITE;
                }
            })
        } catch (error) {
            SignUpStore.update(s => {
                s.loading = false;
            });
            Swal.fire({
                title: 'Error!',
                text: error,
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }
    }

    const validateAllFormIsFilledCorrectly = async () => {

        console.log('isclicked');

        if (state.paymenthMethod === ACH) {
            formRefACH.current.handleSubmit();
        } else {

            const mytoken = document.getElementById("mytoken");
            const tokenform = document.getElementById("tokenform");
            const cardHolderNameformError = document.getElementById("cardHolderNameformError")
            const cardHolderName = document.getElementById("cardHolderNameId")

            cardHolderName.addEventListener("keyup", (e) => {
                if (e.target.value === "" || e.target.value.length < 5) {
                    cardHolderNameformError.classList.remove("d-none");
                } else {
                    cardHolderNameformError.classList.add("d-none");
                }
            })

            let isValid = false;
            if (cardHolderName.value === "") {
                cardHolderNameformError.classList.remove("d-none");
            } else {
                isValid = true;
                cardHolderNameformError.classList.add("d-none");
            }
            if (mytoken.value === "") {
                tokenform.classList.add("formErrorCard");
                isValid = false;
            } else {
                isValid = true;
                tokenform.classList.remove("formErrorCard");
            }

            if (isValid) {
                const validateEmail = await validateEmailForUse(null, false)
                if (validateEmail.result === false) {
                    document.getElementById("emailId").focus();
                    document.getElementById("emailId").blur();
                } else {
                    formRef.current.handleSubmit();
                }
            }
        }
    }

    const validateEmail = async (e, inlineValidation = null) => {
        const validation = await validateEmailForUse(e, true)
        const spanholder = document.getElementById("emailInvalidChars");
        if (validation.result) {
            spanholder.classList.add("d-none");
        } else {
            spanholder.classList.remove("d-none");
            spanholder.innerHTML = validation.msg;
        }
    }

    const url = () => {
        if (!["production", "stage"].includes(process.env.REACT_APP_ENV.toLocaleLowerCase())) {
            return "https://fts-uat.cardconnect.com";
        } else {
            return "https://fts.cardconnect.com";
        }
    }

    const payMSelected = (paymenthMethodSelected) => paymenthMethodSelected === state.paymenthMethod ? "bgPaymentSelectorActive" : "bgPaymentSelectorInactive";

    const handlePaymentMethod = (e) =>
        SignUpStore.update(s => {
            s.paymenthMethod = e.target.innerText === "Credit/Debit Card" ? CREDIT_DEBIT_CARD : ACH;
        });


    return (
        <>
            {/* Formik form */}
            <Formik
                initialValues={util.initialValues}
                innerRef={formRef}
                validationSchema={util.SignupSchema}
                onSubmit={async (values) => {
                    await submitForm(values)
                }}
            >
                {({
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        {/* Form fields */}
                        <div className="container pt-3">
                            <h4><FontAwesomeIcon icon={faUserAlt} /> <span className="pl-2">Subscriber Information</span></h4>
                            <div className="row">
                                <div className="col-md-6">
                                    <div><label htmlFor="firstNameId">First Name</label></div>
                                    <div><Field type="firstName" id="firstNameId" name="firstName" className="form-control" /></div>
                                    <ErrorBox errors={errors} touched={touched} field="firstName" />
                                </div>
                                <div className="col-md-6">
                                    <div><label htmlFor="lastNameId">Last Name</label></div>
                                    <div><Field type="lastName" id="lastNameId" name="lastName" className="form-control" /></div>
                                    <ErrorBox errors={errors} touched={touched} field="lastName" />
                                </div>
                                <div className="col-md-6">
                                    <div><label htmlFor="emailId">Email</label></div>
                                    <div>
                                        <Field onBlur={async (e) => {
                                            await validateEmail(e, true)
                                        }}
                                            type="email"
                                            id="emailId"
                                            name="email"
                                            className="form-control"
                                        />
                                    </div>
                                    <span className="d-none formError" id="emailInvalidChars"></span>
                                    <ErrorBox errors={errors} touched={touched} field="email" />
                                </div>
                                <div className="col-md-6">
                                    <div><label htmlFor="phoneNumberId">Phone Number</label></div>
                                    <div>
                                        <Field name="phoneNumber">
                                            {
                                                ({ field }) => <MaskedInput
                                                    {...field}
                                                    type="text"
                                                    id="phoneNumberId"
                                                    mask={phoneNumberMask}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                />
                                            }
                                        </Field>
                                        <ErrorBox errors={errors} touched={touched} field="phoneNumber" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div><label htmlFor="addressId">Address</label></div>
                                    <div><Field type="address" id="addressId" name="address" className="form-control" /></div>
                                    <ErrorBox errors={errors} touched={touched} field="address" />
                                </div>
                                <div className="col-md-6">
                                    <div><label htmlFor="countryId">Country</label></div>
                                    <div>
                                        <Field as="select" type="country" id="countryId" name="country" className="form-control">
                                            <option value=""> -- </option>
                                            <option value="USA">USA</option>
                                        </Field>
                                    </div>
                                    <ErrorBox errors={errors} touched={touched} field="country" />
                                </div>
                                <div className="col-md-6">
                                    <div><label htmlFor="stateId">State</label></div>
                                    <div>
                                        <Field as="select" type="state" id="stateId" name="state" className="form-control">
                                            <option value=""> -- </option>
                                            {
                                                util.USA_STATES.map((item, index) => <option key={index} value={item.abbreviation}>{item.name}</option>)
                                            }
                                        </Field>

                                    </div>
                                    <ErrorBox errors={errors} touched={touched} field="state" />
                                </div>
                                <div className="col-md-6">
                                    <div><label htmlFor="cityId">City</label></div>
                                    <div><Field type="city" id="cityId" name="city" className="form-control" /></div>
                                    <ErrorBox errors={errors} touched={touched} field="city" />
                                </div>
                                <div className="col-md-6">
                                    <div><label htmlFor="zipId">Zip</label></div>
                                    <div>
                                        <Field name="zip">
                                            {
                                                ({ field }) => <MaskedInput
                                                    {...field}
                                                    type="text"
                                                    mask={zipMask}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                />
                                            }
                                        </Field>
                                    </div>
                                    <ErrorBox errors={errors} touched={touched} field="zip" />
                                </div>

                            </div>
                        </div>
                        {/* Submit button */}
                    </Form>
                )}
            </Formik>

            {/* CARD */}
            <div className="container pt-3">
                <h4><FontAwesomeIcon icon={faCreditCard} /> <span className="pl-2">Payment Information</span></h4>
                <div className="row">
                    <div className="col-md-12">
                        <div>
                            <label htmlFor="cardHolderNameId">Name of credit card holder</label>
                        </div>
                        <div>
                            <input type="text" id="cardHolderNameId" name="cardHolderNameId" className="form-control" />
                            <span className="d-none formError" id="cardHolderNameformError">Card holder name is required</span>
                        </div>

                    </div>
                    <div className="col-md-12 bgPaymentSelectorContainer">
                        <div className="d-flex justify-content-around bgPaymentSelector ">
                            <div onClick={handlePaymentMethod} className={`bgPayment ${payMSelected(CREDIT_DEBIT_CARD)}`}><FontAwesomeIcon icon={faCreditCard} /><span className="p-2">Credit/Debit Card</span></div>
                            <div onClick={handlePaymentMethod} className={`bgPayment ${payMSelected(ACH)}`}><FontAwesomeIcon icon={faBuildingColumns} /><span className="p-2">ACH</span></div>
                        </div>
                    </div>
                    {
                        state.paymenthMethod === CREDIT_DEBIT_CARD ?
                            <>
                                <div className="col-md-12">
                                    <div>
                                        <form name="tokenform" id="tokenform">
                                            <iframe
                                                title="tokenizer iframe"
                                                id="tokenFrame"
                                                name="tokenFrame"
                                                src={`${url()}/itoke/ajax-tokenizer.html?css=${cssStyleInput}&useexpiry=true&usecvv=true&cardnumbernumericonly=true&invalidexpiryevent=true&invalidcvvevent=true&invalidcreditcardevent=true&cardinputmaxlength=16&enhancedresponse=true`}
                                                frameBorder="0"
                                                width="100%"
                                                height="200"
                                                scrolling="no"></iframe>
                                            <input
                                                type="hidden" name="mytoken" id="mytoken" />
                                            <input
                                                type="hidden" name="expiry" id="expiry" />
                                        </form>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="col-md-12">
                                    <Formik
                                        initialValues={util.initialValuesACH}
                                        validationSchema={util.ACHSchema}
                                        onSubmit={async (values) => {
                                            console.log(values);
                                            // If validation went through, submit form
                                            formRef.current.handleSubmit();
                                        }}
                                        innerRef={formRefACH}
                                    >
                                        {({
                                            errors,
                                            touched,
                                            handleChange,
                                            handleSubmit,
                                        }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <div className="col-md-12">
                                                    <div>
                                                        <label htmlFor="accountNumber">Account Number</label>
                                                    </div>
                                                    <div>
                                                        <Field id="accountNumber" name="accountNumber" className="form-control" />
                                                        <ErrorBox errors={errors} touched={touched} field="accountNumber" />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div><label htmlFor="routingNumber">Routing Number</label></div>
                                                    <div>
                                                        <Field name="routingNumber"
                                                            id="routingNumber"
                                                            className="form-control"

                                                        />
                                                        <ErrorBox errors={errors} touched={touched} field="routingNumber" />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div><label htmlFor="accountType">Account Type</label></div>
                                                    <div>
                                                        <Field as="select" type="accountType" id="accountType" name="accountType" className="form-control">
                                                            <option value=""> -- </option>
                                                            <option value="ECHK"> Checking </option>
                                                            <option value="ESAV"> Savings </option>

                                                        </Field>

                                                    </div>
                                                    <ErrorBox errors={errors} touched={touched} field="accountType" />
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </>
                    }

                </div>
            </div>
            <div className="d-flex justify-content-center">
                <div>
                    <p>
                        <label htmlFor="idTerms">
                        <input id="idTerms" className="mr-2" type="checkbox" onChange={(e) => {
                            SignUpStore.update(s => {
                                s.acceptTerms = e.target.checked;
                            })
                        }} />
                            Accept Terms and Conditions
                        </label>
                    </p>
                    <p className="text-center">
                        <a href="https://revrex.com/terms-of-use/" target="_blank" rel="noreferrer">Terms and Conditions</a>
                    </p>
                </div>

            </div>

            <div className="mt-5 d-flex justify-content-center mb-3">
                <button onClick={(e) => {
                    e.preventDefault();
                    setTimeout(() => {
                        validateAllFormIsFilledCorrectly()
                    }, 600);
                }} type="submit"
                    disabled={!state.acceptTerms}
                    className="btn btn-lg btnPay">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                    <span>Create Account</span>
                </button>
            </div>
            <button style={{ visibility: "hidden" }} id="mybutton">test</button>

            <LoadingModal show={state.loading} />
        </>
    );
}

// Export SignUpForm component
export default SignUpForm;